<template>
  <v-card class="mt-10">
    <v-card-title>פרסום חדש</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form">
        <v-row class="basic mt-2">
          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              outlined
              dense
              :rules="[(v) => !!v || 'חובה']"
              v-model="banner.name"
            >
              <template slot="label">
                <strong class="red--text">*</strong>
                תיאור
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="6" md="4">
            <v-file-input
              background-color="white"
              outlined
              dense
              v-model="banner.image"
              @click="image_err = false"
              :rules="[
                (v) => !!v || 'חובה',
                () => !image_err || 'סוג התמונה לא מתאים (jpg, jpeg, png)',
              ]"
              prepend-icon=""
              prepend-inner-icon="image"

            >
              <template slot="label">
                <strong class="red--text">*</strong>
                תמונה
              </template>
            </v-file-input>
          </v-col>

          <v-col cols="6" md="4">
            <v-text-field
              background-color="white"
              readonly
              outlined
              dense
              label="סטטוס"
              :value="banner.status ? 'פעיל' : 'לא פעיל'"
            >
              <template slot="append">
                <v-switch
                  v-model="banner.status"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </template>
            </v-text-field>
          </v-col>

          <!-- <v-col cols="6" md="4">
            <v-checkbox
              v-model="banner.is_manager"
              label="المدير"
            ></v-checkbox>
          </v-col> -->
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end px-4">
      <div class="d-flex">
        <v-btn
          @click="close_form()"
          x-large
          depressed
          height="40"
          color="black--text"
        >
          <v-icon>arrow_forward</v-icon>
        </v-btn>
        <v-btn
          :disabled="save_loader"
          :loading="save_loader"
          x-large
          depressed
          height="40"
          @click="save()"
          color="black white--text ms-3"
          >שמירה</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Form",
  props: ["places", "groups"],
  data() {
    return {
      image_err: false,
      phone_exist: false,
      id_number_exist: false,
      save_loader: false,
      deliveries: [],
      banner: {
        name: "",
        image: null,
        status: true,
      },
    };
  },
  computed: {},
  methods: {
    close_form() {
      if (this.save_loader) {
        this.$emit("update_table");
        this.save_loader = false;
      }
      this.$emit("close_form");
    },
    save() {
      if (this.$refs.form.validate() && !this.save_loader) {
        this.save_loader = true;
        const formData = new FormData();
        formData.append("name", this.banner.name);
        formData.append("image", this.banner.image);
        formData.append("status", this.banner.status);
        this.$store
          .dispatch("public__request", {
            config: {
              url: `banners`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            this.close_form();
          })
          .catch((err) => {
            this.save_loader = false;
            let errors = err.response.data.errors;

            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(this, `${e}_exist`, true);
                  this.$refs.form.validate();
                }
                if (
                  s.includes(
                    "The image must be a file of type: jpeg, jpg, png."
                  )
                ) {
                  this.image_err = true;
                  this.$refs.form.validate();
                }
              });
            });
          });
      }
    },
    get_deliveries() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "banner",
            method: "get",
          },
        })
        .then((res) => {
          this.deliveries = Object.assign([], res.data.data);
        });
    },
  },
  mounted() {
    // this.get_deliveries();
  },
};
</script>
