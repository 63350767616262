<template>
  <v-container>
    <div class="d-block d-md-flex">
      <AppAside></AppAside>
      <div class="ps-5 page_content grow">
        <Table
          ref="table"
          @close_form="close_form()"
          :data_loaded="data_loaded"
          :items="[]"
          @data_loaded="data_loaded = true"
          @update_data="get_data()"
        ></Table>
        <v-btn
          elevation="8"
          class="mt-5"
          v-if="!form && data_loaded && $store.getters.permission('banners create')"
          @click="form = !form"
          fab
          small
        >
          <v-icon>add</v-icon>
        </v-btn>
        <Form
          @update_table="update_table()"
          @close_form="form = !form"
          v-if="form && $store.getters.permission('banners create')"
        ></Form>
      </div>
    </div>
  </v-container>
</template>

<script>
import Table from "@/components/banners/table";
import Form from "@/components/banners/form";
export default {
  name: "brands",
  data() {
    return {
      form: false,
      data_loaded: false,
      banners: [],
    };
  },
  components: {
    Table,
    Form,
  },
  methods: {
    update_table() {
      console.log("update");
      this.$refs.table.get_data();
    },
    close_form() {
      if (this.$refs.form) {
        this.$refs.form.close_form();
      }
    },
  },
  mounted() {

  },
};
</script>
