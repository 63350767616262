<template>
  <div>
    <v-skeleton-loader v-if="!data_loaded" type="table"></v-skeleton-loader>
    <v-card v-if="data_loaded">
      <v-card-title>
        <v-icon x-large>image</v-icon>
        <h1 class="ms-3">פרסומות</h1>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          class="text-center"
          :headers="headers"
          :items="items"
          mobile-breakpoint="0"
        >
          <template v-slot:item="{ item }">
            <tr @click="edit_row(item)">
              <td>
                <div class="d-flex align-center justify-center">
                  <img
                    width="35"
                    class="ml-3"
                    v-if="item.image_path"
                    :src="`${$store.state.def_path}/${item.image_path}`"
                    alt=""
                  />
                  <img
                    width="35"
                    class="ml-3"
                    v-if="!item.image_path"
                    :src="`${$store.state.def_path}/uploads/none_img.png`"
                    alt=""
                  />
                </div>
              </td>
              <td>{{ item.name }}</td>
              <td>
                <v-chip
                  x-small
                  class="px-2"
                  :color="item.status ? 'success black--text' : 'error'"
                >
                  {{ item.status ? "פעיל" : "לא פעיל" }}
                </v-chip>
              </td>
            </tr>

            <tr v-show="item.form" class="grey lighten-3">
              <td colspan="10">
                <v-form :ref="`form${item.id}`" :class="`dd_${item.id}`">
                  <v-row class="basic mt-2 pb-4">
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        outlined
                        dense
                        :rules="[(v) => !!v || 'חובה']"
                        v-model="item.name"
                      >
                        <template slot="label">
                          <strong class="red--text">*</strong>
                          תיאור
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0">
                      <v-file-input
                        background-color="white"
                        outlined
                        dense
                        v-model="item.image"
                        @click="image_err = false"
                        :rules="[ 
                          () =>
                            !image_err ||
                            'סוג התמונה לא מתאים  (jpg, jpeg, png)',
                        ]"
                        prepend-icon=""
                        prepend-inner-icon="image"
                        label="תמונה"
                      >
                      </v-file-input>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        background-color="white"
                        readonly
                        outlined
                        dense
                        label="סטטוס"
                        :value="item.status ? 'פעיל' : 'לא פעיל'"
                      >
                        <template slot="append">
                          <v-switch
                            v-model="item.status"
                            class="mt-0"
                            hide-details
                          ></v-switch>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="py-0 d-flex justify-space-between mb-5">
                      <v-btn
                        depressed
                        height="40"
                        width="59%"
                        :disabled="item.save_loader"
                        :loading="item.save_loader"
                        color="black white--text"
                        @click="save_update(item)"
                        v-if="$store.getters.permission('banners edit')"
                        >שמירה</v-btn
                      >
                      <v-btn
                        depressed
                        height="40"
                        width="33%"
                        class="mr-2"
                        color="white "
                        @click="delete_row(item.id)"
                        v-if="$store.getters.permission('banners delete')"
                      >
                        <v-icon color="black">delete_sweep </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-snackbar color="#333" left v-model="snackbar_success">
      <p class="white--text font-weight-bold"> עודכן בהצלחה</p>
      <template v-slot:action>
        <v-icon color="success" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>

    <DeleteDialog
      @delete_done="get_data()"
      @close_delete="delete_obj.delete_prop = false"
      :delete_obj="delete_obj"
    ></DeleteDialog>
  </div>
</template>

<script>
import DeleteDialog from "@/components/public/delete";
export default {
  name: "Table",
  props: ["data_loaded", "places", "groups"],
  data() {
    return {
      excel_uploaded: false,
      excel_loader: false,
      excel: null,
      excel_model: false,
      image_err: false,
      snackbar_success: false,
      delete_obj: {
        delete_prop: false,
        delete_url: "banners",
        title: "מחק פרסום",
        id: null,
      },

      headers: [
        {
          value: "name",
          text: "תיאור",
          align: "center",
        },
        {
          value: "image",
          text: "תמונה",
          align: "center",
        },
        {
          value: "status",
          text: "סטטוס",
          align: "center",
        },
      ],
      items: [],
    };
  },
  components: {
    DeleteDialog,
  },
  computed: {
    passowrd_length: (app) => (item) => {
      return item.password.length > 7;
    },
    not_matching: (app) => (item) => {
      return item.password == item.password_confirmation;
    },
  },
  methods: {
    input_change(item, type) {
      this.$set(item, `${type}`, false);
    },
    edit_row(item) {
      this.$emit("close_form");
      this.items.forEach((e) => {
        e.form = false;
      });
      item.form = true;
    },
    get_data() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "/banners",
            method: "get",
          },
        })
        .then((res) => {
          this.$emit("data_loaded");
          this.items = Object.assign([], res.data.data);
        });
    },

    save_update(item) {
      if (this.$refs[`form${item.id}`].validate() && !item.save_loader && this.$store.getters.permission('banners edit')) {
        item.save_loader = true;

        const formData = new FormData();
        formData.append("name", item.name);
        formData.append("image", item.image || '');
        formData.append("status", item.status);

        this.$store
          .dispatch("public__request", {
            config: {
              url: `banners/${item.id}`,
              method: "post",
            },
            data: formData,
          })
          .then((res) => {
            item.save_loader = false;
            this.get_data();
            this.snackbar_success = true;
          })
          .catch((err) => {

            this.save_loader = false;
            let errors = err.response.data.errors;
            Object.keys(errors).forEach((e) => {
              errors[e].forEach((s) => {
                if (s.includes("already been taken")) {
                  this.$set(this, `${e}_exist`, true);
                  this.$refs[`form${item.id}`].validate()
                }
                if (
                  s.includes(
                    "The image must be a file of type: jpeg, jpg, png."
                  )
                ) {
                  this.image_err = true;
                  this.$refs[`form${item.id}`].validate()
                }
              });
            });
          });
      }
    },
    delete_row(id) {
      this.delete_obj.id = id;
      this.delete_obj.delete_prop = true;
    },
  },
  mounted() {
    this.get_data();
  },
};
</script>

<style lang="scss" scoped>
.basic {
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
